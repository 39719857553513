import React from 'react';
import logo from './logo.svg';
import './kudo-temp.css';

function App() {
  return (
    <React.Fragment>
      <h1>
      Kudo ApS
      </h1>
      <img src={logo} className="logo" alt="Kudo" />
    </React.Fragment>
  );
}

export default App;
